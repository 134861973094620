.header-section {
    width: 100%;
    align-items: center;
    text-align: center;
    // background-color: #051315;
    background-color: #fefafa;
    position: relative;
    padding-top: 25px;
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 540px) {
    .header-section {
        padding-top: 40px;
    }
}

.logout {
    label {
        color: #0e1b1d;
        font-weight: 600;
    &:hover {
        color: rgb(235 180 93);
    }
}
}