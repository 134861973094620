.result-header-section {
  top: 0px;
  width: 100%;
  z-index: 10;

  &__fixed {
    background-color: #051315;
    position: relative;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #141c1d;
  }

  &__right-side {
    display: flex;
    width: auto;
    align-items: center;

    &--userdetail {
      margin-left: 10px;
    }

    &--logout {
      cursor: "pointer";
      color: white;
      div {
        &:hover {
          color: rgb(235 180 93);
        }
      }
    }
  }

  &__body-fixed {
    position: fixed;
    top: 20%;
    width: 100%;
    z-index: 150;
    display: block;
  }

  &__body-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #051315 !important;
  }

  .inner-result-header__fixed {
    display: flex;
    justify-content: space-between;
    .cus-label {
      .my-data-label {
        label {
          color: white;
          &:hover {
            color: rgb(235 180 93);
          }
        }
      }
    }
  }

  .inner-result-header__fixed--bottom {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 540px) {
      display: block;
    }

    &--left {
      display: flex;
      gap: 10px;

      @media screen and (max-width: 540px) {
        margin: 0px;
        justify-content: center;

        label {
          margin-bottom: 0px !important;
          font-size: 20px !important;

          @media screen and (max-width: 540px) {
            font-size: 12px !important;
          }
        }
      }
    }

    &--right {
      width: 20%;
      display: flex;
      align-items: center;

      @media screen and (max-width: 992px) {
        width: 40%;
      }

      @media screen and (max-width: 661px) {
        width: 50%;
        margin: 0px;
        justify-content: center;
      }

      @media screen and (max-width: 540px) {
        width: 100%;
        margin: 0px;
        justify-content: center;
      }

      h4 {
        label {
          @media screen and (max-width: 540px) {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            font-size: 20px !important;
          }
        }
      }
    }
  }

  .right-side-image {
    padding: 1px;
  }

  .gridContainer {
    max-width: 1400px;
    min-width: auto;
  }

  .left-side-progress {
    width: 50%;

    h4 {
      margin: 0px;
    }
  }
}
