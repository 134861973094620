.progrss-body {
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 140px;
  //   height: 69.9vh;
  overflow: auto;

  &__fixed {
    display: flex;
    display: block;
    // background-color: #051315;
    background-color: #fefafa;
    padding: 5px 129px 5px;
    margin-bottom: 78px;

    @media screen and (max-width: 992px) {
      padding: 5px 17px 5px;
    }
  }

  &__header {
    padding: 5px 129px 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 24%;
    overflow: auto;
    z-index: 11;
    height: 80%;

    @media screen and (max-width: 992px) {
      padding: 5px 50px 5px;
    }
  }

  @media screen and (max-width: 992px) {
    top: 165px;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  // background: #6b7374;
  background: #000;
  border-radius: 10px;
}

.progress-footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  // background: #041214;
  background: #fefafa;
  z-index: 100;

  &__fix-footer {
    // background-color: #051315;
    background-color: #fefafa;
    position: relative;
    padding: 14px 0px;
    display: flex;
    justify-content: space-between;
  }

  .btn {
    height: 48px !important;
  }

  &__button {
    &--deactive {
      background-color: #727a7b !important;
      color: #fff !important;
      // color: #000;
      font-size: 15px !important;
      margin: 0px !important;
    }

    &--active {
      background-color: #0bd7ee !important;
      color: #000 !important;
      font-size: 15px !important;
      margin: 0px !important;
    }
  }

  .gridContainer {
    max-width: 1400px;
    min-width: auto;
  }
}
