.top-result-details {
  position: relative;

  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    height: 155px;
  }
}

.image-container {
  display: flex;
  align-items: center;
  min-width: 20px;
  max-width: 50px;

  img {
    border-radius: 50px;
    image-rendering: -webkit-optimize-contrast;
    object-fit: cover;
  }
}

.ab-top-result-details {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  p {
    // padding-right: 20px;
    // text-transform: uppercase;
    // letter-spacing: 0.1em;
    color: white;
    font-size: 14px;
  }
}

.tooltip-title {
  font-style: italic;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 900;
}

.inner-bg-result {
  padding: 0px 20px;

  .sub-header {
    p {
      // color: #687072;
      color: #e29c2e;
      font-size: 20px;
      font-weight: 800;
    }
  }

  .MuiTypography-root {
    color: white !important;
    font-size: 15px !important;

    @media screen and (max-width: 540px) {
      font-size: 12px !important;
    }
  }
}

.MuiAccordionSummary-content {
  align-items: center;
  justify-content: space-between;
}

.MuiAccordion-root {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid #2d393a;
  margin-bottom: 15px;
  border-radius: 15px !important;
}

// .MuiTypography-root:nth-child(1) {
//   font-size: 17px !important;

//   @media screen and (max-width: 540px) {
//     font-size: 13px !important;
//   }
// }

.MuiAccordionSummary-expandIconWrapper {
  color: white !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: auto !important;
  border-bottom: 1px solid #2d393a !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0px !important;
}

.MuiAccordion-root.Mui-expanded:last-of-type {
  margin: 16px 0 !important;
}

.inner-bg-result {
  ul {
    list-style-type: none;
    list-style-position: inside;
    margin: 0px;
    padding: 0px;

    li {
      padding: 0px;
      margin: 0px;
      margin-bottom: 10px;
      font-size: 16px;
      color: #faaa2f;
      text-decoration: underline;

      &:nth-last-child(1) {
        margin-bottom: 0px;
      }

      &:hover {
        a {
          color: #faaa2f;
          text-decoration: underline;
        }
      }

      // &::before {
      //   content: "";

      //   display: inline-block;

      //   height: 14px;

      //   width: 15px;

      //   background-size: 15px;

      //   background-image: url("../../assets/images/light-list.png");

      //   background-repeat: no-repeat;

      //   margin-right: 5px;
      // }

      a {
        color: white;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}

.delete-button-div {
  display: grid;
  justify-content: end;
  margin-right: 20px;
}

$light-bg-color: rgba(0, 0, 0, 0.1);
$dark-bg-color: rgba(0, 0, 0, 0.2);
$animation-duration: 1.5s;

.skeleton-highlight {
  background-color: $light-bg-color; // Light background color
  border-radius: 4px; // Optional: Rounded corners
  animation: pulse $animation-duration infinite; // Pulsing effect

  @keyframes pulse {
    0% {
      background-color: $light-bg-color;
    }
    50% {
      background-color: $dark-bg-color;
    }
    100% {
      background-color: $light-bg-color;
    }
  }
}

@keyframes hearbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
