.cus-res-wrap {
    display: inline-block;
    width: auto !important;
    float: right;
}

.cus-checkbox-parent {
    .MuiButtonBase-root {
        padding-bottom: unset !important;
        padding-top: 2px !important;
    }
    
    .MuiFormControlLabel-root {
        margin-bottom: 10px;
        -webkit-align-items: self-start;
        -webkit-box-align: start;
        align-items: self-start;
    }
}


@media screen and (max-width: 786px) {
    .cus-checkbox-parent {
        .modal-button {
            height: 44px !important;
        }
    }
    /* .cus-res-wrap {
        float: none;
        text-align: center;
        width: 88% !important;
    } */
}
.cus-label2{
    color: #fff;
    font-weight: 600;
}