@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");


body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // font-family: "Lexend", serif;
  // font-family: avenir-reg !important;
  font-family: "Lexend", serif !important;
  font-optical-sizing: auto;
}


img {
  width: 100%;
}

a {
  text-decoration: unset;
}

.topBottomSpace {
  padding-top: 50px;
  padding-bottom: 50px;
}

/*Slider CSS*/
.slick-next,
.slick-prev {
  z-index: 5;
}

.slick-next {
  right: 15px;
}

.slick-prev {
  left: 15px;
}

.slick-next:before,
.slick-prev:before {
  color: #000;
  font-size: 26px;
}

section.slider.topBottomSpace {
  padding-top: 20px;
}

.bg-lightt {
  background-color: #fff;
}

.main_Slider {
  position: relative;

  .buttonCSS {
    a {
      z-index: 9999;
      color: #000;
      text-decoration: unset;
      padding: 25px 35px;
      background-color: #ffae00;
      border-radius: 35px;
      position: absolute;
      bottom: 2%;
      right: 5%;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      font-family: "Lexend", serif !important;
      font-weight: 400;
      // img {
      // padding-left: 10px;
      //   max-width: 35px;
      // }
    }
  }
}

/*section 2*/
.assessents {
  .heading_1 {
    font-size: 40px !important;
    line-height: 65px !important;
    text-transform: uppercase;
    font-weight: 600 !important;
    font-family: "Lexend", serif !important;
    // font-family: avenir-reg !important;
  }

  .innerTextt {
    color: #2f2f2f;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    // text-align: justify !important;
    font-family: "Lexend", serif !important;
    // font-family: avenir-reg !important;
  }

  .explore {
    a {
      color: #000000;
      font-size: 18px;
      line-height: 26px;
      font-weight: 600;
      padding-right: 15px;
      font-family: "Lexend", serif !important;
    }

    img {
      max-width: 30px;
    }
  }

  // .imagePart {
  //   img {
  //     max-width: 70%;
  //   }
  // }
}

.clientSlide {
  display: flex;
  align-items: center;
  gap: 10px;
}

.clientslideImgs img {
  max-width: 125px !important;
}

section.client_Slider {
  background-color: #f6f9ff;
}

.lightText {
  font-size: 35px;
  // line-height: 45px;
  font-weight: 300;
}

.darkText {
  font-size: 35px;
  font-weight: 700;
  // line-height: 45px;
}

.client_Sliders {
  background-image: url("../../assets//images//bg_1.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

.imgside {
  background-image: url("../../assets/images/bg_2.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 100% 100%;
  height: 200px;
  position: absolute;
  width: 165px;
}

.centercontent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_Slider .swiper-pagination-bullet-active {
  background-color: #ffae00;
  width: 50px;
  border-radius: 10px;
}

.main_Slider span.swiper-pagination-bullet {
  background-color: #ffae00;
}

.main_Slider .swiper-pagination {
  padding-bottom: 15px;
}

.clientName {
  display: flex;
  align-items: center;

  small {
    font-size: 8px;
    padding-left: 8px;
  }
}

section.userJourney {
  background-image: url("../../assets/images/zikzak.png");
  background-repeat: no-repeat;
  background-position: 100% 65%;
  background-size: 100% 45%;

  .joourneyImg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      margin-top: 25px;
    }

    .dotCircle {
      border-radius: 50%;
      outline: 2px dashed orange;
      outline-offset: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 165px;
    }
  }

  .two {
    margin-top: 50px;
  }

  .three {
    margin-top: 130px;
  }
}

/*Footer CSS*/
section.footer-section {
  background-color: #0e1b1d;
  border-top: 6px solid #ffae00;

  .footerSection {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 35px 0;

    .socials {
      display: flex;
      flex-direction: column;

      .details {
        ul {
          display: flex;
          justify-content: space-between;
          color: #fff;

          li {
            padding: 0 15px 0 15px;
            color: #0e1b1d;
            list-style: none;

            a {
              color: #fff;
              font-weight: 400;
              font-size: 16px;
            }
          }
        }
      }

      .socialsIcons {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
          max-width: 40px;
          padding: 0 15px;
        }
      }
    }
  }
}

section.userJourney.topBottomSpace {
  position: relative;
}

.dotimg {
  position: absolute;
  background-image: url("../../assets//images/bg_2.png");
  width: 100%;
  background-repeat: no-repeat;
  height: 100%;
  background-position: 0% 100%;
}

.dotimg2 {
  position: absolute;
  background-image: url("../../assets/images/bg_1.png");
  width: 100%;
  background-repeat: no-repeat;
  height: 100%;
  background-position: 100% 0;
  top: 0;
}




.buttonCSS a {
  display: flex;
  align-items: center;
  /* Align text and image vertically */
  justify-content: center;
  gap: 8px;
  /* Space between text and image */
  text-decoration: none;
  font-weight: bold;
  color: black;
}

.buttonCSS img {
  width: 20px;
  height: auto;
}

/* Default Slide Style */
.clientSlide {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 10px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  opacity: 0.7;
  transform: scale(1);
}

/* Active Slide */
.swiper-slide-active .clientSlide {
  transform: scale(1.6);
  /* Increase size of active slide */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  opacity: 1;
}

.main_Slider .wrapper {
  z-index: 10 !important;
}



.my-client-slider .swiper-slide.swiper-slide-prev .clientInfo {
  display: none;
  font-size: 0;
}

.my-client-slider .swiper-slide.swiper-slide-next .clientInfo {
  display: none;
  font-size: 0;
}

.my-client-slider .swiper-slide.swiper-slide-next {
  display: flex !important;
  justify-content: center !important;
}

.my-client-slider .swiper-slide.swiper-slide-prev {
  display: flex !important;
  justify-content: center !important;
}

.my-client-slider .swiper-slide {
  display: flex !important;
  height: 300px !important;
}

.my-client-slider .clientSlide {
  box-shadow: unset;
  background-color: unset;
}

.my-client-slider .swiper-pagination-bullet-active {
  background-color: #ffae00;
  width: 50px;
  border-radius: 10px;
}

.my-client-slider span.swiper-pagination-bullet {
  background-color: #ffae00;
}

.my-client-slider .swiper-button-prev::after {
  color: #ffae00;
}

.my-client-slider .swiper-button-next::after {
  color: #ffae00;
}

// .my-client-slider .swiper-slide.swiper-slide-active .clientSlide {
//   max-width: 500px !important;
// }

.my-client-slider .swiper-slide.swiper-slide-active .clientslideImgs img {
  max-width: 300px !important;
}

.clientInfo {
  max-width: 200px !important;
}

.clientInfo p {
  font-size: 10px;
  line-height: 14px;
  font-weight: 300;
}


@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .assessents {
    .heading_1 {
      font-size: 30px;
      line-height: 50px;
    }
  }

  .topBottomSpace {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .lightText {
    font-size: 25px;
    line-height: 30px;
  }

  .darkText {
    font-size: 25px;
    line-height: 35px;
  }

  .main_Slider {
    & .buttonCSS {
      a {
        bottom: unset;
        padding: 10px;
        font-size: 13px;
        left: 0;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .assessents {
    .container {
      margin-top: 25px;

      .heading_1 {
        font-size: 25px;
      }
    }
  }

  .clientSlide {
    flex-direction: column;
  }

  section.footer-section {
    & .footerSection {
      flex-direction: column;

      & .socials {
        .socialsIcons {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        & .details {
          padding-top: 20px;
          padding-bottom: 10px;

          ul {
            padding-left: 0;
          }
        }
      }
    }
  }

  .footerLogo {
    width: 100%;
    text-align: center;

    img {
      width: 59%;
    }
  }

  .reverseSec {
    flex-direction: column-reverse;

    .imagePart {
      text-align: center;
    }
  }

  section.userJourney {
    background-image: unset;

    .three {
      margin-top: 0;
    }

    .two {
      margin-top: 0;
    }

    .joourneyImg {
      margin-top: 35px !important;
    }
  }

  .topBottomSpace {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .darkText {
    font-size: 25px;
    line-height: 30px;
  }

  .lightText {
    font-size: 25px;
    line-height: 30px;
  }

  .main_Slider {
    & .buttonCSS {
      bottom: unset;
      left: 0;
      text-align: center;

      a {
        bottom: unset;
        padding: 10px;
        font-size: 13px;
        left: 0;
        text-align: center;
      }
    }
  }
}