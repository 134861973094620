.questions-list {
    &__question {
      background: #0e1b1d;
      border: 2px solid #1d2a2b;
      border-radius: 15px;
  
      &--main {
        display: flex;
        padding: 20px;
  
        &--count {
          margin-left: 20px;
          font-size: 20px !important;
  
          @media screen and (max-width: 540px) {
            margin-left: 0px !important;
          }
        }
  
        &--question {
          margin-left: 20px;
          width: 100%;
  
          @media screen and (max-width: 540px) {
            margin-left: 5px;
          }
        }
  
        @media screen and (max-width: 992px) {
          padding: 10px;
        }
      }
  
      &__active {
        border: 2px solid #86640e;
      }
    }
  
    .break-text {
      white-space: break-spaces !important;
    }
  
    .congrats-block {
      background: url(../../../assets/images/bg-celebration.png) no-repeat white
        top;
      background-size: cover;
      border-radius: 15px;
      display: flex;
      padding: 20px;
      align-items: center;
  
      &__left-side {
        background-color: #40ce79;
        min-width: 66px;
        max-width: 66px;
        height: 66px;
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
  
        @media screen and (max-width: 540px) {
          display: none;
        }
      }
  
      &__right-side {
        margin-left: 15px;
  
        h3 {
          margin: 0px;
          padding: 0px;
          margin-bottom: 10px;
        }
  
        p {
          margin: 0px;
          padding: 0px;
        }
      }
    }
  }
  
  .no-share-modal {
    ul {
      padding-inline-start: 18px;
  
      li {
        color: white;
        list-style-type: disclosure-closed;
        margin-bottom: 0.6em;
        font-size: 0.9rem;
      }
    }
  
    * {
      letter-spacing: 0.6px;
    }
  }
  
  .question-title {
    font-size: 20px !important;
  }
  
  @media screen and (max-width: 540px) {
    .congrats-block {
      display: none !important;
    }
    .congrats-block__left-side {
      display: none !important;
    }
    .congrats-block__right-side {
      display: none !important;
    }
  }