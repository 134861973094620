.footer-section {

    border-top: 1px solid #705e5e;
    width: 100%;
    // background-color: #051315;
    background-color: #fefafa;

}


.menu-list-section {
    width: 33.33%;

    ul {
        display: flex;

        li {
            text-decoration: none;
            display: inline-block;
            padding-right: 10px;
            padding-left: 10px;
            border-right: 1px solid #705e5e;

            a {
                // color: white;
                font-weight: 600;
                color: #0e1b1d;
                text-decoration: none;
                font-size: 14px;
            }
        }

    }
}

.menu-list-section ul li:nth-last-child(1) {
    border-right: 0px;
}

.inner-footer-section {
    display: flex;
    justify-content: space-between;
}

.social-section {
    width: 33.33%;
    justify-content: flex-end;
    display: flex;

    li {
        text-decoration: none;
        display: inline-block;
        padding-right: 10px;
        padding-left: 10px;
    }
}

.copyright-section {
    width: 33.33%;
    text-align: center;
    // color: white;
    color: #0e1b1d;
    font-weight: 600;
}

@media screen and (max-width: 992px) {
    .inner-footer-section {

        flex-direction: column;
    }

    .menu-list-section {
        width: 100%;

        ul {
            display: flex;
            padding: 0px;
            margin: 0px;
            padding-top: 10px;
            justify-content: center;

            li {
                a {
                    font-size: 12px;
                }
            }
        }
    }

    .copyright-section {
        width: 100%;

        p {
            margin-bottom: 0px;
            font-size: 12px;
        }
    }

    .social-section {
        width: 100%;
        justify-content: center;

        ul {

            padding: 0px;
            margin: 13px 0px;

            li {
                img {
                    width: 20px;
                }
            }
        }
    }
}