.terms-class {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin-bottom: 0;
    font-weight: 100;
    line-height: 1.6em;
    width: 350px;
  }
  
  .signup-form {
    .cus-phone-input {
      margin-top: 6px;
    }
  
    .phone-with-error {
      margin-bottom: 20px;
    }
  }