.width-100 {
  width: 100% !important;
}

.chart-block {
  height: 720px !important;
  width: 35% !important;
  border: 2px solid #283436;
  border-radius: 15px;
  background-size: cover !important;
  background: url(../../../assets/images/result-chart-background.png) no-repeat
    white bottom;
  margin-right: 20px;
  flex-direction: column;
  display: flex;

  div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .MuiIconButton-root:hover {
    background-color: white !important;
  }

  .web-share-buttons {
    gap: 6px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .mobile-share-buttons {
    display: none;
  }

  @media screen and (max-width: 992px) {
    .web-share-buttons {
      display: none;
    }
    .mobile-share-buttons {
      display: block;
      max-width: 250px;
      margin: auto;
    }
  }

  .discount {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

    a {
      color: white;
      text-align: center;
    }
  }
}

.chart-detail {
  display: flex;
  justify-content: center;

  &__chart {
    width: 310px;
    top: 310px;
    border: 2px solid #536e72;
    border-radius: 50%;
    border-style: dashed;
    padding: 18px;
  }
}

.chart-title {
  //   margin-top: 80px;
  text-align: center;
  text-transform: uppercase !important;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 540px) {
    // margin-top: 25px;
  }
}

.score-result {
  margin-top: 50px;
  text-align: center;

  @media screen and (max-width: 540px) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.result-body {
  .result-body {
    &__fixed {
      padding: 0px;
      padding-bottom: 100px;
    }
  }

  .gridContainer {
    max-width: 1400px;
    min-width: auto;
  }
}

.result-block {
  width: 65% !important;
  height: auto !important;
  border: 2px solid #283436;
  border-radius: 15px;
  background-color: #0e1b1d;
  margin-left: 20px;
}

.ab-close-pop-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.delete-out-card-box-wrap {
  display: flex;
  justify-items: center;
  padding: 5px 10px;
  padding-bottom: 0px;
}

.out-card-box-wrap {
  display: flex;
  justify-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 25px 30px;
  padding-bottom: 20px;

  .right-desc-wrap {
    padding-left: 15px;
    padding-right: 25px;

    b {
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.1em;
    }

    p {
      margin: 0px;
      margin-top: 5px;
      font-size: 12px;
      color: #727a7b;
    }
  }
}

.inner-success-modal {
  padding: 0px !important;
  width: 630px !important;
}

.delete-inner-success-modal {
  width: auto !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.delete-inner-body-pop-up-wrap {
  padding: 10px 10px;
  margin-bottom: 5px;
  overflow-y: auto !important;

  display: grid;
  justify-content: center;

  .ok-button {
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    width: 100px !important;
  }
}

.inner-body-pop-up-wrap {
  padding: 25px 30px;
  margin-bottom: 25px;
  height: 228px !important;
  overflow-y: auto !important;

  @media screen and (max-width: 540px) {
    height: 380px !important;
  }

  p {
    font-size: 14px;
    line-height: 1.6em;
    letter-spacing: 0.021em;
    color: #364143;
  }

  ul {
    list-style: none;
    padding: 0px;
    padding-left: 20px;

    li {
      font-size: 14px;
      line-height: 1.6em;
      letter-spacing: 0.021em;
      margin-bottom: 10px;
      color: #364143;

      span {
        font-family: avenir-bold;
        color: #364143;
      }

      &::before {
        content: "\2022";
        color: #faaa2f;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 786px) {
  .flex-column {
    flex-direction: column;
  }

  .chart-block {
    height: auto !important;
    width: 100% !important;

    margin-right: 0px;
  }

  .result-block {
    width: 100% !important;
    margin-top: 50px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 481px) {
  .ab-top-result-details {
    position: absolute;
    top: 0px !important;
    left: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .delete-button-div {
    margin-right: 20px !important;
    padding-left: 10px;
  }
}

@media screen and (max-width: 540px) {
  .w-95 {
    width: 95% !important;
  }
}

.chart-detail__chart {
  width: 260px !important;
  top: 310px;
  border: 2px solid #536e72;
  border-radius: 50%;
  border-style: dashed;
  padding: 18px;
}