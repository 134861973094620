.result-header-section__right-side--logout {
    color: #0e1b1d;
    font-weight: 600;
}
.cus-label {
    color: #0e1b1d;
    font-weight: 600;
}
div#root {
    background-color: #fefafa;
}
